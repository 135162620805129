// extracted by mini-css-extract-plugin
export var Section1 = "BinLoyalty-module--Section1--75741";
export var Section1__button = "BinLoyalty-module--Section1__button--167e4";
export var Section1__description = "BinLoyalty-module--Section1__description--5ba90";
export var Section1__image = "BinLoyalty-module--Section1__image--99732";
export var Section1__subtitle = "BinLoyalty-module--Section1__subtitle--5d33d";
export var Section1__title = "BinLoyalty-module--Section1__title--454ab";
export var heroSection__button = "BinLoyalty-module--heroSection__button--50a86";
export var heroSection__button__wrapper = "BinLoyalty-module--heroSection__button__wrapper--4995a";
export var heroSection__content = "BinLoyalty-module--heroSection__content--b1037";
export var heroSection__description = "BinLoyalty-module--heroSection__description--105d4";
export var heroSection__title = "BinLoyalty-module--heroSection__title--ab220";
export var hero__wrapper = "BinLoyalty-module--hero__wrapper--5f207";
export var plans__specialPlanCard = "BinLoyalty-module--plans__specialPlanCard--8a330";