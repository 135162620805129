import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import { cn } from "../../utils/cn";

const MotionLink = motion(Link);

const KeyFeatures = ({ images }) => {
  const { t } = useTranslation();
  const features = [
    {
      title: t("binLoyalty.keyFeatures.1.title"),
      description: t("binLoyalty.keyFeatures.1.description"),
      image: getImage(images[0].node.childImageSharp),
    },
    {
      title: t("binLoyalty.keyFeatures.2.title"),
      description: t("binLoyalty.keyFeatures.2.description"),
      image: getImage(images[1].node.childImageSharp),
    },
    {
      title: t("binLoyalty.keyFeatures.3.title"),
      description: t("binLoyalty.keyFeatures.3.description"),
      image: getImage(images[2].node.childImageSharp),
    },
    {
      title: t("binLoyalty.keyFeatures.4.title"),
      description: t("binLoyalty.keyFeatures.4.description"),
      image: getImage(images[3].node.childImageSharp),
    },
  ];

  return (
    <motion.section
      {...revealContainerWithChildrenProps}
      className={cn([
        "sectionContainer",
        "flex flex-col items-center gap-8 w-full",
        "lg:gap-[50px]",
        "mb-[128px]",
        "lg:mb-[113px]",
      ])}
    >
      <h1 className={cn(["font-bold text-3xl text-black", "lg:text-4xl"])}>
        {t("binLoyalty.keyFeatures.title")}
      </h1>
      <motion.div
        className={cn([
          "grid grid-cols-1 gap-10",
          "md:grid-cols-2 md:gap-x-[42px] md:gap-y-[38px]",
        ])}
      >
        {features.map((feature, index) => (
          <Feature key={index} {...feature} />
        ))}
      </motion.div>
      <MotionLink
        to="/products/zeal-bin-loyalty#schedule"
        variants={revealVariant}
        className={`button button--gradient sm:w-full lg:w-fit`}
      >
        {t("binLoyalty.keyFeatures.button")}
      </MotionLink>
    </motion.section>
  );
};

const Feature = ({ title, description, image }) => {
  return (
    <motion.div
      variants={revealVariant}
      className={cn([
        "bg-white rounded-3xl",
        "flex gap-[18px]",
        "px-5 py-7",
        "lg:px-[33px] lg:py-[33px]",
      ])}
    >
      <div className="hidden lg:block ">
        <GatsbyImage image={image} alt={title} />
      </div>
      <div className={cn(["flex flex-col gap-[18px]", "lg:gap-1"])}>
        <div className={cn(["flex items-center gap-4"])}>
          <div className="lg:hidden ">
            <GatsbyImage image={image} alt={title} />
          </div>
          <h2 className={cn(["text-black text-xl font-bold", ""])}>{title}</h2>
        </div>
        <p className={cn(["text-black text-lg font-normal", ""])}>
          {description}
        </p>
      </div>
    </motion.div>
  );
};

export default KeyFeatures;
