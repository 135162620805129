import React from "react";
import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
import { ThreeDots } from "react-loader-spinner";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { Toaster, toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { useLocation } from '@reach/router';

import { api } from "../../utils/api";
import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";

const EmailGuide = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const location = useLocation();
  const fullUrl = location.href;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await fetch(`${api}/v3/bin/ebook`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
      body: JSON.stringify({
        email: data.email,
        url: fullUrl,

      }),
    });
    if (response.status === 200) {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: "formSubmitted",
          formName: "LG_BL_BIN",
          formPosition: "body",
        });
      }
      const res = await response?.json();
      toast.success(res?.message || "Thank you for contacting us");
      setValue("email", "");
      setIsFormSubmitted(true);
    } else {
      try {
        const error = await response?.json();
        const errorMessages = Object.values(error?.errors || {});

        toast.error(
          errorMessages?.[0] || error?.message || "Something went wrong"
        );
      } catch (error) {
        toast.error("Something went wrong");
      }
    }
    setIsLoading(false);
  };

  return (
    <motion.section
      {...revealContainerWithChildrenProps}
      className={clsx([
        "bg-[#FFD860]",
        "px-[28px] py-[77px] mb-[100px]",
        "lg:px-[40px] lg:pr-[150px] lg:py-[70px] lg:mb-[120px]",
      ])}
    >
      <Toaster />
      <div
        className={clsx([
          "flex",
          "flex-col items-center gap-[40px]",
          "lg:flex-row lg:justify-center lg:gap-[90px]",
        ])}
      >
        <motion.div
          variants={revealVariant}
          className={clsx([
            "flex-1 max-lg:ml-[-50px] 2xl:ml-[125px]",
            "max-w-[450px]",
          ])}
        >
          <StaticImage
            src="../../images/bin-loyalty/Book-Mockup.png"
            alt="Book Mockup"
            quality={100}
            placeholder="blurred"
            layout="constrained"
          />
        </motion.div>
        <motion.div
          {...revealContainerWithChildrenProps}
          className={clsx([
            "flex flex-1 flex-col 2xl:mr-[150px] lg:max-w-[655px]",
            "items-start sm:max-lg:items-center sm:max-lg:text-center",
            "max-lg:max-w-[560px]",
          ])}
        >
          <motion.h1
            variants={revealVariant}
            className={clsx([
              "text-[24px] font-bold text-[#1D1D1D] mb-4",
              "lg:text-[36px] lg:mb-[30px]",
            ])}
          >
            {t("binLoyalty.emailGuide.title")}
          </motion.h1>
          <motion.p
            variants={revealVariant}
            className={clsx([
              "text-[18px] font-normal text-[#1D1D1D] mb-[40px]",
              "lg:text-[24px] lg:mb-[42px]",
            ])}
          >
            {t("binLoyalty.emailGuide.description")}
          </motion.p>
          <motion.div
            variants={revealVariant}
            className="max-w-[650px] mb-[20px] lg:mb-[28px] text-start w-full"
          >
            <label htmlFor="fullName">
              {t("binLoyalty.emailGuide.email")}
              <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="fullName"
              placeholder={t("binLoyalty.emailGuide.emailPlaceHolder")}
              className={`input  ${errors.fullName ? "error" : ""}`}
              {...register("email", {
                required: true,
                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              })}
            />
            {errors.email && (
              <span className="text-base font-semibold text-red-500">
                {errors.email.type === "required"
                  ? t("binLoyalty.emailGuide.error.required")
                  : t("binLoyalty.emailGuide.error.emailPattern", {
                    field: t("binLoyalty.emailGuide.email").toLowerCase(),
                  })}
              </span>
            )}
          </motion.div>
          <motion.button
            variants={revealVariant}
            type="submit"
            className={`button button--secondary max-w-[650px] flex justify-center w-full`}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? (
              <ThreeDots
                height="28"
                width="28"
                color="#fff"
                ariaLabel="loading"
                radius="3"
                visible={true}
                wrapperClass="flex justify-center"
              />
            ) : (
              t("binLoyalty.emailGuide.button")
            )}
          </motion.button>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default EmailGuide;
