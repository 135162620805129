import React from "react";
import { graphql } from "gatsby";

///Components
import Layout from "../../components/Layout";
import {
  Analytics,
  DataSecurity,
  EmailGuide,
  Hero,
  KeyFeatures,
  Plans,
  Section1,
  Section4,
  Solution,
  Schedule,
} from "../../components/zeal-bin-loyalty";

const ZealBinLoyalty = ({ data: { Section1Image, featuresImages } }) => {
  return (
    <Layout>
      <Hero />
      <Section1 image={Section1Image} />
      <KeyFeatures images={featuresImages.edges} />
      <Solution />
      <Section4 />
      <EmailGuide />
      <Analytics />
      <Plans />
      <Schedule />
      <DataSecurity />
    </Layout>
  );
};

export default ZealBinLoyalty;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "zeal-bin-loyalty"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    Section1Image: file(name: { regex: "/section1-pos/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    featuresImages: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { regex: "/^key-feature/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              layout: FIXED
              width: 56
              quality: 100
            )
          }
        }
      }
    }
  }
`;
