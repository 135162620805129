import React from "react";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import HeroGradient from "../../components/HeroGradient";
import MouseScrollAnimated from "../../components/MouseScrollAnimated";

import * as styles from "../../styles/components/BinLoyalty.module.css";
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import clsx from "clsx";

const Hero = () => {
  return (
    <section className={styles.hero__wrapper}>
      <HeroGradient />
      <motion.div
        {...revealContainerWithChildrenProps}
        className={clsx([
          "sectionContainer",
          "relative z-50",
          "flex flex-col items-center justify-between gap-20",
          "md:flex-row md:items-start",
        ])}
      >
        <HeroText />
        <HeroImage />
      </motion.div>
      <MouseScrollAnimated />
    </section>
  );
};

const HeroText = () => {
  const { t } = useTranslation();

  return (
    <motion.div variants={revealVariant} className="">
      <h1
        className={styles.heroSection__title}
        dangerouslySetInnerHTML={{
          __html: t("binLoyalty.heroSection.title", {
            highlight: `<span>${t(
              "binLoyalty.heroSection.title.highlight"
            )}</span>`,
          }),
        }}
      />
      <p className={styles.heroSection__description}>
        {t("binLoyalty.heroSection.description")}
      </p>
      <div className={styles.heroSection__button__wrapper}>
        <Link
          to="/products/zeal-bin-loyalty/#schedule"
          className={`${styles.heroSection__button} button button--primary`}
        >
          {t("binLoyalty.heroSection.button")}
        </Link>
      </div>
    </motion.div>
  );
};

const HeroImage = () => {
  return (
    <>
      {/* desktop */}
      <motion.div
        className="h-[493px] hidden xl:block elevetion-animation"
        initial="hidden"
        animate="visible"
        variants={revealParent}
      >
        <motion.div variants={revealCards}>
          <StaticImage
            className="relative !z-50 top-[205px]"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            src="../../images/bin-loyalty/visa-3.png"
            alt="hero logo visa cards"
            width={456}
            placeholder="blurred"
            style={{
              maxWidth: "456px",
              width: "456px",
            }}
          />
        </motion.div>
        <motion.div variants={revealCards}>
          <StaticImage
            className="relative !z-40 !top-[-200px]"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            src="../../images/bin-loyalty/visa-2.png"
            alt="hero logo visa cards"
            width={456}
            placeholder="blurred"
            style={{
              maxWidth: "456px",
              width: "456px",
            }}
          />
        </motion.div>
        <motion.div variants={revealCards}>
          <StaticImage
            className="relative !z-30 !top-[-580px]"
            layout="fullWidth"
            formats={["auto", "webp", "avif"]}
            src="../../images/bin-loyalty/visa-1.png"
            alt="hero logo visa cards"
            width={456}
            placeholder="blurred"
            style={{
              maxWidth: "456px",
              width: "456px",
            }}
          />
        </motion.div>
      </motion.div>

      {/* mobile */}
      <motion.div
        variants={revealVariant}
        className="block xl:hidden elevetion-animation"
      >
        <StaticImage
          className="relative z-50"
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
          src="../../images/bin-loyalty/hero-visa-mobile.png"
          alt="hero logo visa cards"
          width={280}
          height={362}
          style={{
            maxWidth: "280px",
            width: "280px",
            height: "362px",
          }}
        />
      </motion.div>
    </>
  );
};

export const revealParent = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
      duration: 1,
    },
  },
};
export const revealCards = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default Hero;
