import React from "react";
import { motion } from "framer-motion";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";

import * as styles from "../../styles/components/BinLoyalty.module.css";

const MotionLink = motion(Link);

const Section1 = ({ image }) => {
  const { t } = useTranslation();

  const product = {
    title: t("binLoyalty.section1.title"),
    description: t("binLoyalty.section1.description"),
    button: t("binLoyalty.section1.button"),
    image: getImage(image.childImageSharp),
    aspectRatio:
      image.childImageSharp.gatsbyImageData.height /
      image.childImageSharp.gatsbyImageData.width,
    link: "/products/zeal-bin-loyalty/#schedule",
    brands: [],
  };

  return (
    <section className="sectionContainer !mt-[265px] lg:!mt-[158px] mb-[100px] lg:mb-[110px]">
      <motion.div
        {...revealContainerWithChildrenProps}
        variants={revealVariant}
        className={`${styles.vertical__Img} ${styles.Section1}`}
        key={product.title}
      >
        <motion.div {...revealContainerWithChildrenProps}>
          <motion.h3
            variants={revealVariant}
            className={styles.Section1__title}
          >
            {product.title}
          </motion.h3>
          <motion.p
            variants={revealVariant}
            className={styles.Section1__description}
          >
            {product.description}
          </motion.p>

          <MotionLink
            variants={revealVariant}
            to={product.link}
            className={`button with-icon button--gradient ${styles.Section1__button}`}
          >
            {product.button}
          </MotionLink>
        </motion.div>
        <motion.div
          variants={revealVariant}
          className={`${styles.Section1__image} ${"elevetion-animation"}`}
        >
          <GatsbyImage image={product.image} alt={product.title} />
        </motion.div>
      </motion.div>
    </section>
  );
};

export default Section1;
