import React from "react";
import { motion } from "framer-motion";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import { cn } from "../../utils/cn";

const MotionLink = motion(Link);

const Section4 = () => {
  const { t } = useTranslation();

  return (
    <motion.section
      {...revealContainerWithChildrenProps}
      className={cn(["sectionContainer"])}
    >
      <div
        className={cn([
          "relative",
          "bg-white rounded-3xl shadow-sm mb-[100px] py-[54px] px-[24px]",
          "lg:mb-[120px] lg:py-[70px] lg:pl-[70px] xl:pr-0",
          "flex flex-col-reverse gap-[53px]",
          "xl:flex-row xl:gap-[38px]",
        ])}
      >
        <TextContent />
        <VisualContent />
      </div>
    </motion.section>
  );
};

const TextContent = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      variants={revealVariant}
      className={cn(["flex-1 flex flex-col gap-[20px]"])}
    >
      <h1 className={cn(["text-black text-[28px] font-bold ", "lg:text-4xl"])}>
        {t("binLoyalty.section4.title")}
      </h1>
      <h2
        className={cn([
          "text-black font-semibold text-[24px]",
          "lg:text-[26px]",
        ])}
      >
        {t("binLoyalty.section4.subtitle")}
      </h2>
      <p className={cn(["text-black text-lg", "lg:text-[20px]"])}>
        {t("binLoyalty.section4.description")}
      </p>

      <MotionLink
        to="/products/zeal-bin-loyalty/#schedule"
        variants={revealVariant}
        className={`button button--gradient lg:max-w-[223px]`}
      >
        {t("binLoyalty.section4.button")}
      </MotionLink>
    </motion.div>
  );
};

const VisualContent = () => {
  const { t } = useTranslation();

  return (
    <motion.div variants={revealVariant} className="flex-1 flex justify-center">
      {/* Desktop Image */}
      <div className="flex-1 hidden xl:block">
        <StaticImage
          src={"../../images/bin-loyalty/section4/mac-desktop.png"}
          alt={"Section4"}
          quality={100}
          layout="fullWidth"
          placeholder="blurred"
        />
      </div>
      {/* Mobile Image  */}
      <div className="w-full max-w-[462px] xl:hidden">
        <StaticImage
          src={"../../images/bin-loyalty/section4/mac-mobile.png"}
          alt={"Section4"}
          quality={100}
          layout="fullWidth"
          placeholder="blurred"
        />
      </div>

      <FloatingCards />
    </motion.div>
  );
};

const FloatingCards = () => (
  <motion.div>
    <div
      className={cn([
        "elevetion-animation absolute ",
        "top-[0px] right-[70px] w-[240px]",
        "xl:top-[0px] xl:right-[220px] xl:w-[371px]",
      ])}
    >
      <StaticImage
        src={"../../images/bin-loyalty/section4/info-card-1.png"}
        alt={"Section4"}
        quality={100}
        placeholder="blurred"
        layout="fullWidth"
      />
    </div>
    <div
      className={cn([
        "elevetion-animation absolute",
        "top-[170px] left-[50px] w-[240px]",
        "xl:top-auto xl:left-auto xl:bottom-[-40px] xl:right-[150px] xl:w-[371px]",
      ])}
    >
      <StaticImage
        src={"../../images/bin-loyalty/section4/info-card-2.png"}
        alt={"Section4"}
        quality={100}
        placeholder="blurred"
        layout="fullWidth"
      />
    </div>
  </motion.div>
);

export default Section4;
