import React from "react";
import { motion } from "framer-motion";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage, getImage } from "gatsby-plugin-image";

import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import { cn } from "../../utils/cn";

const MotionLink = motion(Link);

const Solution = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("binLoyalty.solution.1.title"),
      description: t("binLoyalty.solution.1.description"),
      image: (
        <StaticImage
          src={"../../images/bin-loyalty/solution/card.png"}
          alt={t("binLoyalty.solution.1.title")}
          quality={100}
          placeholder="blurred"
          width={67}
          height={67}
        />
      ),
    },
    {
      title: t("binLoyalty.solution.2.title"),
      description: t("binLoyalty.solution.2.description"),
      image: (
        <StaticImage
          src={"../../images/bin-loyalty/solution/earn-points.png"}
          alt={t("binLoyalty.solution.2.title")}
          quality={100}
          placeholder="blurred"
          width={67}
          height={67}
        />
      ),
    },
    {
      title: t("binLoyalty.solution.3.title"),
      description: t("binLoyalty.solution.3.description"),
      image: (
        <StaticImage
          src={"../../images/bin-loyalty/solution/loyalty-program.png"}
          alt={t("binLoyalty.solution.3.title")}
          quality={100}
          placeholder="blurred"
          width={67}
          height={67}
        />
      ),
    },
  ];

  return (
    <motion.section
      {...revealContainerWithChildrenProps}
      className={cn([
        "bg-white flex flex-col items-center gap-[60px] mb-[100px]",
        "px-6 py-[61px]",
        "lg:py-90 lg:mb-[120px]",
      ])}
    >
      <h1 className={cn(["text-black text-[28px] font-bold", "lg:text-4xl"])}>
        {t("binLoyalty.solution.title")}
      </h1>
      <div
        className={cn([
          "flex flex-wrap items-center justify-center gap-[52px]",
          "lg:flex-row lg:flex-wrap lg:gap-[90px] lg:items-start lg:justify-center",
        ])}
      >
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </div>
      <MotionLink
        to="/products/zeal-bin-loyalty/#schedule"
        variants={revealVariant}
        className={`button button--gradient`}
      >
        {t("binLoyalty.solution.button")}
      </MotionLink>
    </motion.section>
  );
};

const Item = ({ title, description, image }) => {
  return (
    <motion.div
      variants={revealVariant}
      className={cn([
        "flex flex-col items-center gap-6 ",
        "max-w-[320px] lg:w-[320px]",
      ])}
    >
      {image}
      <div className={cn(["flex flex-col items-center gap-2"])}>
        <h2 className={cn(["text-black text-[24px] font-bold text-center"])}>
          {title}
        </h2>
        <p className={cn(["text-black text-[18px] text-center"])}>
          {description}
        </p>
      </div>
    </motion.div>
  );
};

export default Solution;
