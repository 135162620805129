import React from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage } from 'gatsby-plugin-image';
import clsx from "clsx";
import { motion } from "framer-motion";

import * as styles from "../../styles/components/BinLoyalty.module.css";
import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";
import CheckedIcon from '../../images/bin-loyalty/checked-icon.svg';
import NotCheckedIcon from '../../images/bin-loyalty/not-checked-icon.svg';

const MotionLink = motion(Link);

const Plans = () => {
  const { t } = useTranslation();

  return (
    <motion.section
      className={clsx([
        "px-[46px]",
        "flex flex-col items-center !mb-[100px]",
        "lg:px-[70px]",
      ])}
    >
      <motion.div
        {...revealContainerWithChildrenProps}
        className={clsx([
          "flex items-center flex-col gap-4 mb-6",
          "lg:gap-3 lg:mb-8",
        ])}
      >
        <motion.h1
          variants={revealVariant}
          className={clsx([
            "font-bold text-[28px] text-center",
            "lg:text-[36px]",
          ])}
        >
          {t("binLoyalty.plans.title")}
        </motion.h1>
        <motion.p
          variants={revealVariant}
          className={clsx([
            "font-normal text-[18px] text-center !opacity-70",
            "lg:text-[20px]",
          ])}
        >
          {t("binLoyalty.plans.subtitle")}
        </motion.p>
      </motion.div>
      <motion.div
        variants={revealVariant}
        className="flex items-center gap-3 mb-[40px] lg:mb-[42px]"
      >
        {/* <img src={ToggleIcon} alt="Toggle Icon" />
        <motion.p
          className={clsx([
            "text-brand-blue",
            "font-normal text-[18px]",
            "lg:text[21px]",
          ])}
        >
          {t("binLoyalty.plans.annualMessage")}
        </motion.p> */}
      </motion.div>
      <PlansCards />
      <MotionLink
        to="/products/zeal-bin-loyalty/#schedule"
        variants={revealVariant}
        className={`button button--gradient mt-[40px] lg:my-[60px]`}
      >
        {t("binLoyalty.plans.button")}
      </MotionLink>
    </motion.section>
  );
};

const PlansCards = () => {
  const { t } = useTranslation();
  const plans = t("binLoyalty.plans.plans", {
    returnObjects: true,
    defaultValue: [],
  });

  return (
    <motion.div
      // {...revealContainerWithChildrenProps}
      className={clsx(["flex flex-wrap gap-8 justify-center"])}
    >
      {plans.map((plan, idx) => (
        <PlanCard key={plan.title || idx} plan={plan} />
      ))}
    </motion.div>
  );
};

const PlanCard = ({ plan }) => {

  return (
    <motion.div
      variants={revealVariant}
      className={clsx([
        "hoverable",
        "bg-white rounded-2xl border border-[#D0D5DD] border-solid max-w-[283px]",
        "py-[36px] px-[22px]",
        "flex flex-col gap-4",
        plan?.isSpecial && styles.plans__specialPlanCard,
      ])}
    >
      <motion.div
        variants={revealVariant}
        className={clsx(["flex flex-col gap-[10px]"])}
      >
        <h2
          className={clsx([
            "text-[27px] text-[#191D23] font-bold",
            plan?.isSpecial && "!text-white",
          ])}
        >
          {plan.title}
        </h2>
        <p
          className={clsx([
            "text-[#64748B] text-[15px] font-normal leading-normal",
            "h-[40px]",
            plan?.isSpecial && "!text-white",
          ])}
        >
          {plan.description}
        </p>
        {plan?.features?.map((feature, idx) => (
          <div key={idx} className="flex items-center gap-[10px]">
            <GatsbyImage src={feature?.isAvailable ? CheckedIcon : NotCheckedIcon} />
            <p
              className={clsx([
                "text-[15px] font-normal text-[#191D23]",
                plan?.isSpecial && "!text-white",
              ])}
            >
              {feature.title}
            </p>
          </div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Plans;
