import React from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

import {
  revealContainerWithChildrenProps,
  revealVariant,
} from "../../utils/animation-variants";

const DataSecurity = () => {
  return (
    <motion.section
      className={clsx([
        "bg-white relative mb-[100px]",
        "max-lg:px-[23px] max-lg:pb-[94px]",
        "lg:pl-[150px] lg:pt-[74px] lg:pb-[54px] lg:mb-[35px]",
        "flex flex-col items-center",
        "lg:flex-row-reverse lg:gap-10 2xl:gap-20 lg:justify-center lg:items-center",
      ])}
    >
      <VisualContent />
      <TextContent />
    </motion.section>
  );
};

const VisualContent = () => {
  return (
    <motion.div
      {...revealContainerWithChildrenProps}
      className="max-lg:w-full flex justify-center"
    >
      <div className="absolute right-0 top-0 max-lg:max-w-[100px] overflow-hidden">
        <StaticImage
          src={"../../images/bin-loyalty/data-security/bg-overlay.svg"}
          alt="Data Security"
          placeholder="blurred"
          layout="fixed"
          quality={100}
        />
      </div>
      <motion.div
        variants={revealVariant}
        className={clsx([
          "elevetion-animation",
          "lg:hidden w-full max-w-[226px] md:max-w-[360px]",
        ])}
      >
        <StaticImage
          src={
            "../../images/bin-loyalty/data-security/data-security-mobile.png"
          }
          alt="Data Security"
          placeholder="blurred"
          layout="fullWidth"
          quality={100}
        />
      </motion.div>
      <motion.div
        className={clsx(["hidden lg:block", "relative left-[-90px]"])}
      >
        <StaticImage
          src={"../../images/bin-loyalty/data-security/bg-img.png"}
          alt="Data Security"
          placeholder="blurred"
          layout="constrained"
          quality={100}
        />
        <motion.div
          variants={revealVariant}
          className="absolute top-1/3 left-[45%] "
        >
          <StaticImage
            src={"../../images/bin-loyalty/data-security/lock.png"}
            alt="Data Security"
            placeholder="blurred"
            layout="constrained"
            quality={100}
          />
        </motion.div>
        <motion.div
          variants={revealVariant}
          className="absolute top-1/2 left-[18%] elevetion-animation"
        >
          <StaticImage
            src={"../../images/bin-loyalty/data-security/circle-1.png"}
            className="lg:max-xl:scale-75"
            alt="Data Security"
            placeholder="blurred"
            layout="constrained"
            quality={100}
          />
        </motion.div>
        <motion.div
          variants={revealVariant}
          className="absolute top-[73%] left-[25%] elevetion-animation"
        >
          <StaticImage
            src={"../../images/bin-loyalty/data-security/circle-2.png"}
            className="lg:max-xl:scale-75"
            alt="Data Security"
            placeholder="blurred"
            layout="constrained"
            quality={100}
          />
        </motion.div>
        <motion.div
          variants={revealVariant}
          className="absolute top-[30%] right-[5%] elevetion-animation"
        >
          <StaticImage
            src={"../../images/bin-loyalty/data-security/circle-group.png"}
            className="lg:max-xl:scale-75"
            alt="Data Security"
            placeholder="blurred"
            layout="constrained"
            quality={100}
          />
        </motion.div>
        <motion.div
          variants={revealVariant}
          className="absolute top-[60%] right-[-10%] elevetion-animation"
        >
          <StaticImage
            src={"../../images/bin-loyalty/data-security/circle-3.png"}
            className="lg:max-xl:scale-75"
            alt="Data Security"
            placeholder="blurred"
            layout="constrained"
            quality={100}
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

const TextContent = () => {
  const { t } = useTranslation();
  const items = t("binLoyalty.dataSecurity.items", {
    returnObjects: true,
    defaultValue: {},
  });

  return (
    <motion.div
      {...revealContainerWithChildrenProps}
      className={clsx(["flex flex-col gap-[42px]", "lg:max-w-[533px]"])}
    >
      <div
        className={clsx(["flex flex-col gap-4", "items-center lg:items-start"])}
      >
        <motion.h1
          variants={revealVariant}
          className={clsx(["text-[28px] font-bold"])}
        >
          {t("binLoyalty.dataSecurity.title")}
        </motion.h1>
        <motion.p
          variants={revealVariant}
          className={clsx(["text-base opacity-70"])}
        >
          {t("binLoyalty.dataSecurity.description")}
        </motion.p>
      </div>
      <div className={clsx(["flex flex-col gap-8"])}>
        <Item
          title={items?.GDPR?.title}
          description={items?.GDPR?.description}
          icon={<StaticImage src={"../../images/bin-loyalty/data-security/gdpr-icon.svg"} alt={items?.GDPR?.title} />}
        />
        <Item
          title={items?.SSL?.title}
          description={items?.SSL?.description}
          icon={<StaticImage src={"../../images/bin-loyalty/data-security/ssl-icon.svg"} alt={items?.SSL?.title} />}
        />
        <Item
          title={items?.Tokenization?.title}
          description={items?.Tokenization?.description}
          icon={<StaticImage src={"../../images/bin-loyalty/data-security/tokenizzation-icon.svg"} alt={items?.Tokenizati} />}
        />
        <Item
          title={items?.PCI?.title}
          description={items?.PCI?.description}
          icon={<StaticImage src={"../../images/bin-loyalty/data-security/pci.png"} alt={items?.PCI?.title} />}
        />
      </div>
    </motion.div>
  );
};

const Item = ({ title, description, icon }) => {
  return (
    <motion.div
      variants={revealVariant}
      className={clsx(["flex gap-8 items-center"])}
    >
      {icon}
      <div className={clsx(["flex flex-col gap-[6px]"])}>
        <h1 className={clsx(["font-bold text-[22px]"])}>{title}</h1>
        <p className={clsx(["text-lg "])}>{description}</p>
      </div>
    </motion.div>
  );
};

export default DataSecurity;
