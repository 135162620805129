import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

import ScheduleSection from "../ScheduleSection";

const Schedule = () => {
  const { t } = useTranslation();

  return (
    <section id="schedule" className="mb-[100px]">
      <ScheduleSection
        title={t("binLoyalty.schedule.title")}
        subtitle={t("binLoyalty.schedule.description")}
      />
    </section>
  );
};

export default Schedule;
